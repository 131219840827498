<template>
  <div class="page-content">
    <yandex-map :settings="settings" :options="options" :controls="controls" :coords="currentLocation.coords"
      :zoom="currentLocation.zoom" :cluster-options="clusterize" @map-was-initialized="initializeMap($event)"
      @boundschange="boundsChange($event)">
      <MapMarker v-for="marker in visible" :key="marker.id + '_' + interactionKey" :item="marker"></MapMarker>
      <ymap-marker v-if="isDot" marker-id="dot" :icon="dot" :coords="currentLocation.coords"></ymap-marker>
    </yandex-map>
    <div class="map__controls">
      <div class="map__button-group">
        <span class="map__button" @click="increaseZoom()">
          <img src="@/assets/images/svg/plus.svg" alt="">
        </span>
        <hr>
        <span class="map__button" @click="decreaseZoom()">
          <img src="@/assets/images/svg/minus.svg" alt="">
        </span>
      </div>
      <div class="map__button map__button--block" @click="toUserLocation">
        <img src="@/assets/images/svg/arrow-location.svg" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import { yandexMap, ymapMarker } from 'vue-yandex-maps'
import MapMarker from '@/components/MapMarker.vue'
import DirectionColors from '@/helpers/DirectionColors'
import createCluster from '@/helpers/ClusteringHelper'
import { mapActions, mapGetters } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import debounce from 'lodash/debounce';
import api from '@/requests';

export default {
  name: 'Map',
  components: {
    yandexMap,
    ymapMarker,
    MapMarker
  },
  props: {
    getMap: Function
  },
  data() {
    return {
      interactionKey: 0,
      // map: null,
      settings: {
        apiKey: process.env.VUE_APP_MAP_KEY,
        lang: 'ru_RU'
      },
      options: {
        suppressMapOpenBlock: true,
        yandexMapDisablePoiInteractivity: true
      },
      clusterize: {
        objects: {
          gridSize: 512,
          createCluster: (e, obj) => this.setupCluster(e, obj)
        },
        partners: {
          gridSize: 512,
          createCluster: (e, obj) => createCluster(e, obj, this.visible, this.zoomIn)
        }
      },
      dot: {
        layout: 'default#image',
        imageHref: '/img/dot.svg',
        imageSize: [30, 30]
      },
      controls: [],
      abortGetPartnersController: null
    }
  },
  watch: {
    markers: {
      deep: true,
      handler(val) {
        if (this.map) {
          const bounds = this.map.getBounds();
          this.map.geoObjects.removeAll();
          this.$store.dispatch('setVisible', bounds)
        }
        this.updateInteractionKey();
      }
    },
    // items() {
    //   const bounds = this.bounds;
    //   if (bounds === null) {
    //     return;
    //   }

    //   this.searchCoordinates(bounds);
    //   this.removeAllObjects();
    // },
    activeItem(val) {
      // this.map.geoObjects.removeAll();
      // this.updateInteractionKey();
    },
    currentLocation(newValue, oldValue) {
      if (this.map && JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
        this.map.setCenter(this.currentLocation.coords, this.currentLocation.zoom);
      }
    },
    // currentLocation: {
    //   deep: true,
    //   handler() {
    //     console.log('Current location', this.currentLocation);
    //     if (this.map) {
    //       this.map.setCenter(this.currentLocation.coords, this.currentLocation.zoom);
    //       setTimeout(() => {
    //         const bounds = this.map.getBounds();
    //         const withinVisibleArea = this.markers.filter(object => (object.coords[0] > bounds[0][0]
    //           && object.coords[0] < bounds[1][0]
    //           && object.coords[1] > bounds[0][1]
    //           && object.coords[1] < bounds[1][1]));
    //         // если при поиске ничего не нашлось, делаем зум поменьше
    //         if (this.$route.path.includes('results') && withinVisibleArea.length === 0 && this.currentLocation.zoom > 11) {
    //           this.map.setCenter(this.currentLocation.coords, this.currentLocation.zoom - 1);
    //           this.$store.dispatch('setCurrentLocation', {
    //             coords: this.currentLocation.coords,
    //             zoom: this.currentLocation.zoom - 1
    //           });
    //         } else if (this.$route.path.includes('results') && this.searchResults.length === 0) {
    //           this.$store.dispatch('setSearchResults', withinVisibleArea);
    //         }
    //       }, 0);
    //     }
    //   }
    // },
    activeLayer() {
      if (this.map) {
        this.map.geoObjects.removeAll();
        this.$store.dispatch('setVisible', this.map.getBounds());
      }
    }
  },
  created() {
    // Привязываем контекст debounced функции при создании компонента
    this.debouncedFetch = debounce(this.debouncedFetch.bind(this), 300);
  },
  methods: {
    initializeMap(event) {
      this.setMap(event);
      this.getMap(event);
      // if (this.items.length) {
      //   this.searchCoordinates(this.bounds);
      // } else {
      //   const interval = setInterval(() => {
      //     if (this.items.length) {
      //       this.searchCoordinates(this.bounds);
      //       clearInterval(interval);
      //     }
      //   }, 500);
      // }

      // this.removeAllObjects();

      // if (this.markers.length) {
      //   this.$store.dispatch('setVisible', this.map.getBounds());
      // } else {
      //   const interval = setInterval(() => {
      //     if (this.markers.length) {
      //       this.$store.dispatch('setVisible', this.map.getBounds());
      //       clearInterval(interval);
      //     }
      //   }, 500);
      // }
    },
    async boundsChange(event) {
      console.log('boundsChange');

      const newBounds = event.originalEvent.newBounds;

      const minLat = newBounds[0][0];
      const minLng = newBounds[0][1];
      const maxLat = newBounds[1][0];
      const maxLng = newBounds[1][1];

      const coordinateArea = {
        minLat,
        minLng,
        maxLat,
        maxLng
      };

      // Отложить вызов API на 300 мс после того, как изменения прекратились
      await this.debouncedFetch(coordinateArea);
    },
    async debouncedFetch(coordinateArea) {
      // Отменяем предыдущий запрос
      if (this.abortGetPartnersController) {
        this.abortGetPartnersController.abort();
        console.log('Previous request was aborted.');
      }

      const requestKey = uuidv4();
      console.log('Starting a new request', requestKey);

      // Создаём новый AbortController для текущего запроса
      this.abortGetPartnersController = new AbortController();
      const signal = this.abortGetPartnersController.signal;

      try {
        const items = await api.partners.listByCoordinateArea(coordinateArea, signal);

        // Проверяем, не был ли запрос отменён до обработки результата
        if (signal.aborted) {
          console.log('Request was aborted, ignoring the response.', requestKey);
          return;
        }

        this.setEntities(items);
      } catch (error) {
        if (error.name === 'AbortError') {
          console.warn('Запрос был отменён');
        } else {
          console.error('Произошла ошибка', error);
        }
      }
    },
    setupCluster(center, objects) {
      const pieChartOptions = Object.values(DirectionColors).map(directionColor => ({ weight: 0, color: directionColor }));

      objects.forEach(obj => {
        const item = this.visible.find(marker => marker.id === obj.properties.get('markerId')); // находим объект по id

        if (item) {
          const directions = Array.isArray(item.directions) ? [...new Set(item.directions.map(direction => direction.code.toLowerCase()))] : []; // получаем массив направлений
          for (const direction of directions) {
            const color = DirectionColors[direction];
            const fItem = pieChartOptions.find(option => option.color === color)
            fItem.weight++;
            fItem.objects = objects.map(el => el.properties.get('markerId'))
          }
        }
      });

      const clusterer = new window.ymaps.Clusterer()
      const placemark = new window.ymaps.Placemark(
        center,
        {
          data: pieChartOptions,
          iconContent: objects ? objects.length : ''
        },
        {
          iconLayout: 'default#pieChart',
          iconPieChartRadius: window.innerWidth > 767 ? 35 : 25,
          iconPieChartCoreRadius: window.innerWidth > 767 ? 28 : 20,
          iconPieChartStrokeWidth: 0,
          hasBalloon: false
        }
      );
      clusterer.add(placemark)

      placemark.events.add(['dblclick'], e => {
        const activeLayer = this.layer;
        const zoom = this.zoom;
        if (zoom > 15) {
          const geoObjects = clusterer.getGeoObjects();
          const geoObjectsQuery = window.ymaps.geoQuery(geoObjects);
          const visibleGeoObjects = geoObjectsQuery.searchIntersect(this.map)
          const visibleObjectsHtml = [];
          visibleGeoObjects.each((x) => {
            const content = x.properties.get('data').find(el => el.objects).objects;
            visibleObjectsHtml.push(...content);
          });
          let filteredData = []
          if (activeLayer === 'objects') {
            const obj = this.$store.getters['getObjects'];
            filteredData = obj.filter(el => visibleObjectsHtml.includes(el.id))
          } else {
            const partners = this.$store.getters['getPartners'];
            filteredData = partners.filter(el => visibleObjectsHtml.includes(el.id))
          }
          this.$store.dispatch('setSearchResults', filteredData);
          this.$router.push('/results');
        }
      })

      placemark.events.add(['click'], e => {
        this.zoomIn(e);
      });
      placemark.events.add(['mouseenter'], e => {
        e.get('target').getOverlaySync().getLayoutSync().getElement().children[0].classList.add('cluster_hovered');
      });
      placemark.events.add(['mouseleave'], e => {
        e.get('target').getOverlaySync().getLayoutSync().getElement().children[0].classList.remove('cluster_hovered');
      });
      return clusterer
    },
    toUserLocation() {
      const successCallback = (position) => {
        const coords = [position.coords.latitude, position.coords.longitude];
        this.setCurrentLocation({ coords, zoom: 10 });
      };

      const errorCallback = (error) => {
        this.$services.MessageService.error('Отказано в доступе к геолокации');
        console.error(error.message);
        this.setCurrentLocation({ coords: this.userLocation, zoom: 10 }); // если геолокация не получена, использовать userLocation
      };

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
      } else {
        this.setCurrentLocation({ coords: this.userLocation, zoom: 10 });
      }

      this.$store.dispatch('removeDot');
    },
    zoomIn(e) {
      if (this.map) {
        if (e.get('target').options.get('iconLayout') === 'default#pieChart') {
          const coords = e.get('coords');
          const zoom = this.zoom + 2;
          this.map.setCenter(coords, zoom);
        }
      }
    },
    updateVisible(data) {
      this.$store.dispatch('setVisible', data.originalEvent.newBounds);
    },
    updateInteractionKey() {
      this.map.geoObjects.removeAll();
      this.interactionKey += 1;
    },
    ...mapActions('map', ['setMap', 'removeAllObjects', 'increaseZoom', 'decreaseZoom']),
    ...mapActions('entity', ['searchCoordinates', 'setEntities']),
    ...mapActions('location', ['setCurrentLocation'])
  },
  computed: {
    activeLayer() {
      return this.$store.getters['getActiveLayer'];
    },
    markers() {
      return this.$store.getters['getAvailables'];
    },
    searchResults() {
      return this.$store.getters['getSearchResults'];
    },
    isDot() {
      return this.$store.getters['getDot'];
    },
    activeItem() {
      return this.$store.getters['getActiveItem'];
    },
    visibleEntities() {
      return this.visible;
    },
    ...mapGetters('entity', ['visible', 'items']),
    ...mapGetters('map', ['map', 'bounds', 'zoom']),
    ...mapGetters('location', ['currentLocation', 'userLocation'])
  }
}
</script>

<style scoped lang="scss">
.page-content {
  position: relative;
  width: 100%;
  height: 100%;
}

.filter {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: grayscale(1);
  pointer-events: none;
  z-index: 1;
}

.ymap-container {
  width: 100%;
  height: 100%;
}

.map {
  &__controls {
    position: fixed;
    top: calc(50vh - 85px);
    right: 40px;

    @media (max-width: 767px) {
      top: 50vh;
      right: 10px;
    }

    @media (max-height: 499px) {
      top: 47vh;
    }
  }

  &__button-group {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 50px;
    height: 100px;
    padding: 10px 5px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    margin-bottom: 20px;

    hr {
      width: 100%;
      height: 1px;
      border: none;
      background-color: #BFBFBF;
    }

    @media (max-width: 767px) {
      width: 40px;
      height: 80px;
      padding: 8px;
      margin-bottom: 16px;
    }
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    img {
      width: 30px;
      height: 30px;

      @media (max-width: 767px) {
        width: 18px;
        height: 18px;
      }
    }

    &--block {
      width: 50px;
      height: 50px;
      background-color: #fff;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);

      @media (max-width: 767px) {
        width: 40px;
        height: 40px;
      }

      img {
        width: 25px;
        height: 25px;

        @media (max-width: 767px) {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}
</style>
