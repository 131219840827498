import axios from '../axios';

const list = async () => {
  const response = await axios.get('/api/v1/map/partners');
  return response.data.items || [];
};

const listByCoordinateArea = async (coordinateArea, signal) => {
  const response = await axios.post('/api/v1/map/partners', coordinateArea, { signal });
  return response.data.items || [];
}

const partner = async (id) => {
  const response = await axios.get(`/api/v1/map/partners/${id}`);
  return response.data.item || {};
};

const partners = {
  list,
  listByCoordinateArea,
  partner
};

export default partners;
